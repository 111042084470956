import React, { useCallback, useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import InputMask from 'react-input-mask';

// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

// import { TextSmall } from '../../../../componentes/Interface';

import { CampoDeTextoOutlined } from '../styles';
import { IStoreRedux } from 'Types/Reducers';

const regex = /\([1-9][0-9]\)\s?9?\s?\d{4}([\s-])?\d{4}/;

function CampoCelular() {
  const numero = useSelector((store: IStoreRedux) => store.contatos.celular?.numero ?? '');
  const dispatch = useDispatch();

  const [valido, setValido] = useState<boolean>(false);

  const aoAlterarNumero = useCallback((e) =>
    dispatch({ type: 'ALTERA_CELULAR', numero: e.target.value })
  , [dispatch]);

  const aoAlterarWhatsapp = useCallback((e) =>
    dispatch({ type: 'ALTERA_CELULAR', whatsapp: e.target.checked })
  , [dispatch]);

  useEffect(() => {
    const resultado = regex.test(numero);
    setValido(resultado);
    dispatch({ type: 'VALIDA_CAMPO', celular: resultado });
    if (resultado)
      dispatch({ type: 'ALTERA_CELULAR', numero });
  }, [numero, dispatch]);

  useEffect(() => {
    if (numero === '')
      dispatch({ type: 'ALTERA_CELULAR', numero: null, whatsapp: true });
  }, [dispatch, numero]);

  return (
    <div className='campo-formulario'>
      <InputMask
        mask='(99) 99999-9999'
        maskChar=' '
        value={numero}
        onChange={aoAlterarNumero}
      >
        {
          (inputProps: any) =>
            <CampoDeTextoOutlined
              {...inputProps}
              autoComplete="tel"
              type='tel'
              label='Celular'
              fullWidth
              error={numero !== '' && !valido}
            />
        }
      </InputMask>


      {/* <FormControlLabel
        label={<TextSmall>Este número tem WhatsApp</TextSmall>}
        control={
          <Checkbox
            value={celular.whatsapp}
            color='primary'
            checked={celular.whatsapp}
            onChange={aoAlterarWhatsapp}
          />
        }
      /> */}
    </div>
  );
}

export default CampoCelular;