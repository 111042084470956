import React, { useCallback, useState } from 'react';
import Spinner from '../../../../componentes/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreRedux } from 'Types/Reducers';
import { post } from '../../../../_axios/requisicao';
import RespostaInesperada from '../RespostaInesperada';
import { formataEtapas } from '../config';
import { useHistory } from 'react-router-dom';
import useTentarNovamente from '../../assets/useTentarNovamente';

function InscricaoComCpf({ dispatchEtapas, setTentarInscricaoComCpf, continuarFluxo }: any) {
  // const carregando = useSelector((state: IStoreRedux) => state.app.carregando);
  const hash = useSelector((state: IStoreRedux) => state.app.hash);
  const referencia = useSelector((state: IStoreRedux) => state.app.referencia);
  const dadosInscricao = useSelector((state: IStoreRedux) => ({
    cpf: state.dados.cpf,
    contatos: state.contatos,
    endereco: state.endereco,
    termos: state.termos,
  }));

  const dispatch = useDispatch();
  const history = useHistory();

  const [carregando, setCarregando] = useState(true);

  const retry = useTentarNovamente(
    useCallback(
      () => {
        // dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });
        setCarregando(true);
        const dados = {
          ...dadosInscricao,
          contatos: dadosInscricao.contatos.celular && dadosInscricao.contatos.celular.numero !== null ? dadosInscricao.contatos : undefined, // se null converte para undefined
          endereco: dadosInscricao.endereco && dadosInscricao.endereco.logradouro !== null ? dadosInscricao.endereco : undefined, // se null converte para undefined
        };

        const headerHashOuReferencia = Boolean(hash) ? { hash } : { referencia };

        post('api-pessoas', '/inscricoes-com-cpf', dados, headerHashOuReferencia)
          .then((res) => dispatch({ type: 'ALTERA_INSCRITO', inscrito: res.status === 200 }))
          .catch((e) => {
            // Quando inscricao nao realizada
            if (e.status === 400) {

              // Cadastro nao existe
              if (e.error.cadastro_existe === false) {
                return continuarFluxo();
              }

              // Cadastro existe com dados pendentes
              if (e.error.solicitar_celular || e.error.solicitar_endereco) {
                const solicitacoes = formataEtapas({
                  celular: e.error.solicitar_celular,
                  endereco: e.error.solicitar_endereco && 'completo',
                  termos: [],
                })
                  // remove as etapas obrigatorias que a funcao retorna
                  .filter((et) => !/nome|email/.test(et.path));

                dispatchEtapas((atual: any) => ({
                  ...atual,
                  solicitacoes,
                  posicao: 0,
                }));
                history.push(solicitacoes[0].path);
              }
            }
          })
          .finally(() => {
            // dispatch({ type: 'ALTERA_CARREGANDO', carregando: false });
            setCarregando(false);
            setTentarInscricaoComCpf(false);
          });
      },
      [continuarFluxo, dadosInscricao, dispatch, dispatchEtapas, hash, history, referencia, setTentarInscricaoComCpf]
    )
  );

  return carregando ? <Spinner /> : <RespostaInesperada retry={retry} />;
}

export default InscricaoComCpf;
