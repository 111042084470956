import React, { useEffect, useCallback, useState, ChangeEvent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { get } from '../../../_axios/requisicao';

import Checkbox from '../../../componentes/Checkbox';
import { Text, Subtitle } from '../../../componentes/Interface';

import MuiDialog from '../../../componentes/MuiDialog';

import { IStoreRedux } from 'Types/Reducers';
import { ITermo } from 'Types/Reducers/Termos';
import { detectaDispositivo, getDiaAtual, getMesAtual, getAnoAtual, getReferenciaPeloDominio } from '../../../_recursos/js/util';

interface IStyles {
  [key: string]: any,
};

interface IDadosTermo extends Omit<ITermo, 'aceito' | 'dispositivo'> {
  texto: string
}

interface ICheckboxTermo extends Omit<ITermo, 'aceito' | 'dispositivo'> {
  label: string,
  texto: string,
  titulo: string,
  botao: string,
}

const useStyles = makeStyles<Theme, IStyles>((theme) => ({
  termo: {
    textTransform: 'initial',
    textDecoration: 'underline',
    padding: '0',
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
  },
  labelCheckbox: {
    lineHeight: '1.3',
    fontSize: '1rem',
  }
} as any));

let valorInicialDicionarioTermos: ICheckboxTermo[] = [
  {
    idTermoTipo: 2,
    idTermo: 0,
    label: 'Li e aceito termo de adesão a Serviço Voluntário e o Termo de Confidencialidade e Sigilo.',
    texto: '',
    titulo: 'Termo de Adesão a serviço Voluntario e Termo de Confidencialidade e Sigilo',
    botao: 'Ver Termos'
  },
  {
    idTermoTipo: 7,
    idTermo: 0,
    label: 'Li e autorizo o uso de imagem, nome e voz.',
    texto: '',
    titulo: 'Autorização de uso de imagem, nome e voz',
    botao: 'Ver Termo'
  },
];

function TermosDeAdesao() {
  const [termos, setTermos] = useState<ICheckboxTermo[]>([]);
  const [abrirTermo, setAbrirTermo] = useState<boolean>(false);
  const [tituloTermo, setTituloTermo] = useState<string>('');
  const [conteudoTermo, setConteudoTermo] = useState<string>('');
  const [idTermoTipo, setIdTermoTipo] = useState<number>(0);

  const classes = useStyles({} as IStyles);

  const carregando = useSelector<IStoreRedux, boolean>(state => state.app.carregando);
  const stateTermos = useSelector<IStoreRedux, ITermo[]>(state => state.termos);
  const dados = useSelector<IStoreRedux, IStoreRedux['dados']>(state => state.dados);
  const contatos = useSelector<IStoreRedux, IStoreRedux['contatos']>(state => state.contatos);
  const endereco = useSelector<IStoreRedux, IStoreRedux['endereco']>(state => state.endereco);

  const dispatch = useDispatch();

  const getTermos = useCallback(async () => {
    dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });

    const termosABuscar = valorInicialDicionarioTermos.map(item => item.idTermoTipo)
      .map((termoTipo) => new Promise<IDadosTermo>(async (resolve, reject) => {
        try {
          const { data: [termo] } = await get('api-pessoas', `/pessoas/termos?tipo=${termoTipo}&atual=1`, { schema: getReferenciaPeloDominio() });
          resolve(termo as IDadosTermo)
        } catch (error) {
          reject(error);
        }
      }))

    function atribuirValores(termosObtidos: IDadosTermo[]) {
      const resultado = termosObtidos
        .filter(item => valorInicialDicionarioTermos.find(inicial => inicial.idTermoTipo === item.idTermoTipo))
        .map(item => {
          const correspondencia = valorInicialDicionarioTermos.find(inicial => inicial.idTermoTipo === item.idTermoTipo)
          return { ...correspondencia, idTermo: item.idTermo, texto: item.texto } as ICheckboxTermo
        })
      return resultado
    }

    function iniciarTermos(termos: IDadosTermo[]) {
      setTermos(atribuirValores(termos))
      const termosIniciados: ITermo[] = termos.map(item => ({
        idTermo: item.idTermo,
        idTermoTipo: item.idTermoTipo,
        aceito: false,
        dispositivo: detectaDispositivo()
      }))
      dispatch({ type: 'ADICIONA_TERMOS', termos: termosIniciados })
    }

    Promise.all(termosABuscar)
      .then(iniciarTermos, (erro) => console.error(erro))
      .finally(() => dispatch({ type: 'ALTERA_CARREGANDO', carregando: false }))
  }, [dispatch]);

  /**
   * Manipula o evento de checkbox
   * Ao usuario interagir com a checkbox altera o valor do estado verifica qual termo foi selecionado
   * e altera o estado de termos com o aceite true ou false
   * @param {ChangeEvent<HTMLInputElement} event - Evento disparado pela checkbox
   */
  const alteraTermosSelecionado = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const value = Number(event.target.value);

    const termo = stateTermos.find(item => item.idTermo === value);
    if (termo) {
      dispatch({ type: 'ADICIONA_TERMOS', termos: [{ ...termo, aceito: checked }] })
    }
  }, [dispatch, stateTermos]);

  /**
   * Manipula a abertura dos Termos
   * @param {CheckboxTermoProps} termo - Propriedades do Termo que será exibido
   */
  const abreTermo = useCallback((termo: ICheckboxTermo) => {
    setTituloTermo(termo.titulo);
    setConteudoTermo(termo.texto);
    setIdTermoTipo(termo.idTermoTipo);
    setAbrirTermo(true);
  }, []);

  /**
   * Map no estado termos para criar a renderização das checkbox
   */
  const obterOpcoesCheckbox = useCallback(() => {
    const opcoes = termos.map((termo: ICheckboxTermo) => (
      <Checkbox
        gutterBottom
        key={termo.idTermo}
        rotulo={
          <Text className={classes.labelCheckbox}>{termo.label}&nbsp;
            <Button
              className={classes.termo}
              onClick={() => abreTermo(termo)}
            >
              {termo.botao}
            </Button>
          </Text>
        }
        value={termo.idTermo}
        onChange={alteraTermosSelecionado}
      />
    ));

    return opcoes;
  }, [abreTermo, alteraTermosSelecionado, classes.labelCheckbox, classes.termo, termos]);

  const estadoCivil = useMemo(() => {
    if (dados.idEstadoCivil === 1) {
      return 'Solteiro (a)';
    } else if (dados.idEstadoCivil === 2) {
      return 'Casado (a)';
    } else if (dados.idEstadoCivil === 3) {
      return 'Separado (a)';
    } else if (dados.idEstadoCivil === 4) {
      return 'Divorciado (a)';
    } else if (dados.idEstadoCivil === 5) {
      return 'Viúvo (a)';
    } else if (dados.idEstadoCivil === 6) {
      return 'Amasiado (a)';
    } else if (dados.idEstadoCivil === 7) {
      return 'Não Informado';
    } else {
      return 'Não Informado';
    }
  }, [dados.idEstadoCivil]);

  const textoTermo = useMemo(() => {
    const dia = getDiaAtual().toString();
    const mes = getMesAtual();
    const ano = getAnoAtual().toString();

    if (conteudoTermo) {
      return conteudoTermo
        .replace('{NOME_COMPLETO}', dados.nome as string)
        .replace('{ESTADO_CIVIL}', estadoCivil)
        .replace('{CPF}', dados.cpf as string)
        .replace('{LOGRADOURO}', endereco?.logradouro ?? '')
        .replace('{NUMERO}', endereco?.numero ?? '')
        .replace('{BAIRRO}', endereco?.bairro ?? '')
        .replace('{CIDADE}', endereco?.cidade ?? '')
        .replace('{UF}', endereco?.estado ?? '')
        .replace('{CELULAR}', contatos.celular?.numero ?? '')
        .replace('{NOME_COMPLETO_ANEXO}', dados.nome as string)
        .replace('{CPF_ANEXO}', dados.cpf as string)
        .replace('{DIA_VOLUNTARIADO}', dia)
        .replace('{MES_VOLUNTARIADO}', mes)
        .replace('{ANO_VOLUNTARIADO}', ano)
        .replace('{NOME_FINAL_VOLUNTARIADO}', dados.nome as string)
        .replace('{DIA_CONFIDENCIALIDADE}', dia)
        .replace('{MES_CONFIDENCIALIDADE}', mes)
        .replace('{ANO_CONFIDENCIALIDADE}', ano)
        .replace('{NOME_CONFIDENCIALIDADE}', dados.nome as string)
        .replace('{DIA}', dia)
        .replace('{MES}', mes)
        .replace('{ANO}', ano)
        .replace('{DIA_IMAGEM}', dia)
        .replace('{MES_IMAGEM}', mes)
        .replace('{ANO_IMAGEM}', ano)
    }

    return conteudoTermo;
  }, [contatos.celular.numero, conteudoTermo, dados.cpf, dados.nome, endereco.bairro, endereco.cidade, endereco.estado, endereco.logradouro, endereco.numero, estadoCivil]);

  const init = useCallback(async () => {
    dispatch({ type: 'VALIDA_ETAPA', etapaValida: false });

    await getTermos();
  }, [dispatch, getTermos]);

  useEffect(() => {
    init();
  }, [init]);

  /**
   * Valida etapa
   * Verifica se todos os termos foram aceitos e libera a etapa,
   * caso contrario não libera a etapa
   * @param {ITermo[]} termos - Array de termos
   */
  useEffect(() => {
    const todosValidos = stateTermos.every(termo => termo.aceito);
    dispatch({ type: 'VALIDA_ETAPA', etapaValida: todosValidos });
  }, [dispatch, stateTermos]);

  return (
    <div className="etapa-formulario">
      <Subtitle gutterBottom>Termos de Adesão</Subtitle>

      <div>{obterOpcoesCheckbox()}</div>

      <MuiDialog
        carregando={carregando}
        titulo={tituloTermo}
        conteudo={textoTermo}
        idTermoTipo={idTermoTipo}
        abrir={abrirTermo}
        setAbrir={setAbrirTermo}
      />
    </div>
  );
}

export default TermosDeAdesao;
