import React, { useCallback, useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from "react-redux";
import { TextField, MenuItem, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { InputSuffix, Subtitle } from "../../../componentes/Interface";
import { get } from "../../../_axios/requisicao";
import { primeiraMaiusculaTodasPalavras } from '../../../_recursos/js/util';

import { estadosOpcoes } from './constants';
import { IStoreRedux } from 'Types/Reducers';
import { useAsync, useAsyncFn } from 'react-use';

const regex = {
  cep: /^\d{5}-\d{3}$/,
  estado: /^[A-Z]{2}$/,
  idCidade: /^\d{1,}$/,
  cidade: /^[.a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ() \-'`]{2,}$/,
  bairro: /^[.a-zA-Z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ() \-'`]{2,}$/,
  logradouro: /^[.a-zA-Z0-9áàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ() \-'`]{2,}$/,
  numero: /^[a-zA-Z0-9 ]{1,}$/
};


export default function Endereco() {
  const cep = useSelector<IStoreRedux, string>((store) => store.endereco?.cep || '');
  const estado = useSelector<IStoreRedux, string>((store) => store.endereco?.estado || '');
  const idCidade = useSelector<IStoreRedux, number | undefined>((store) => store.endereco?.idCidade);
  const cidade = useSelector<IStoreRedux, string>((store) => store.endereco?.cidade || '');
  const idBairro = useSelector<IStoreRedux, number | undefined>((store) => store.endereco?.idBairro);
  const bairro = useSelector<IStoreRedux, string>((store) => store.endereco?.bairro || '');
  const logradouro = useSelector<IStoreRedux, string>((store) => store.endereco?.logradouro || '');
  const idLogradouro = useSelector<IStoreRedux, number | undefined>((store) => store.endereco?.idLogradouro);
  const numero = useSelector<IStoreRedux, string>((store) => store.endereco?.numero || '');
  const complemento = useSelector<IStoreRedux, string>((store) => store.endereco?.complemento || '');
  const dispatch = useDispatch();

  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'endereco' }), [dispatch]);

  const setCEP = useCallback((cep: string) => dispatch({ type: 'ALTERA_ENDERECO', cep }), [dispatch]);
  const setEstado = useCallback((estado: string) => dispatch({ type: 'ALTERA_ENDERECO', estado }), [dispatch]);
  const setCidade = useCallback(
    (cidade: Record<string, string | number | undefined>) => (
      dispatch({ type: 'ALTERA_ENDERECO', cidade: cidade.nome, idCidade: ('id' in cidade) ? cidade.id : (idCidade || null) })
    ),
    [dispatch, idCidade]
  );
  const setBairro = useCallback(
    (bairro: Record<string, string | number | undefined>) => (
      dispatch({ type: 'ALTERA_ENDERECO', bairro: bairro.nome, idBairro: ('id' in bairro) ? bairro.id : (idBairro || null) })
    ),
    [dispatch, idBairro]
  );
  const setLogradouro = useCallback(
    (logradouro: Record<string, string | number | undefined>) => (
      dispatch({ type: 'ALTERA_ENDERECO', logradouro: logradouro.nome, idLogradouro: ('id' in logradouro) ? logradouro.id : (idLogradouro || null) })
    ),
    [dispatch, idLogradouro]
  );
  const setNumero = useCallback((numero: string) => dispatch({ type: 'ALTERA_ENDERECO', numero }), [dispatch]);
  const setComplemento = useCallback((complemento: string) => dispatch({ type: 'ALTERA_ENDERECO', complemento }), [dispatch]);

  const [valido, setValido] = useState({
    cep: null as boolean | null,
    estado: null as boolean | null,
    idCidade: null as boolean | null,
    cidade: null as boolean | null,
    bairro: null as boolean | null,
    logradouro: null as boolean | null,
    numero: null as boolean | null
  });

  const localidade = useAsync(async () => {
    if (regex.cep.test(cep)) {
      const retorno = await get('api-enderecos', `/buscar-localidade?cep=${cep}`);
      return {
        estado: retorno.data.estado,
        idCidade: retorno.data.idCidade,
        cidade: retorno.data.cidade,
        idBairro: retorno.data.bairro_id || null,
        bairro: retorno.data.bairro || null,
        idLogradouro: retorno.data.logradouro_id || null,
        logradouro: retorno.data.logradouro || null,
      };
    }

    return undefined;
  }, [cep]);

  useEffect(() => {
    if (localidade.value) dispatch({ type: 'ALTERA_ENDERECO', ...localidade.value });
  }, [dispatch, localidade.value]);

  const [cidades, listarCidades] = useAsyncFn<() => Promise<Array<{ idCidade: number; cidade: string; }>>>(async () => {
    if (regex.estado.test(estado)) {
      const retorno = await get('api-enderecos', `/cidades?uf=${estado}&nome=${cidade}`);
      if (retorno.status === 200) {
        const opcoes = retorno.data.map((item: any) => ({ idCidade: item.idcidade, cidade: item.cidade }));

        if (opcoes.length <= 5) {
          const opcao = opcoes.find((c: any) => cidade.toLowerCase() === c.cidade.trim().toLowerCase());
          if (opcao) setCidade({ nome: opcao.cidade.trim(), id: opcao.idCidade });
        }

        return opcoes;
      }
    }

    return [];
  }, [estado, cidade]);

  const [bairros, listarBairros] = useAsyncFn<() => Promise<Array<{ idBairro: number; bairro: string; }>>>(async () => {
    if (idCidade) {
      const retorno = await get('api-enderecos', `/bairros?id_cidade=${idCidade}&nome=${bairro}`);
      if (retorno.status === 200) {
        const opcoes = retorno.data.map((item: any) => ({ idBairro: item.idbairro, bairro: item.bairro }));

        if (opcoes.length <= 5) {
          const opcao = opcoes.find((b: any) => bairro.toLowerCase() === b.bairro.trim().toLowerCase());
          if (opcao) setBairro({ nome: opcao.bairro.trim(), id: opcao.idBairro });
        }

        return opcoes;
      }
    }

    return [];
  }, [idCidade, bairro]);

  const [logradouros, listarLogradouros] = useAsyncFn<
    () => Promise<Array<{ idLogradouro: number; logradouro: string; faixa: string; cep: string | null; }>>
  >(
    async () => {
      if (idCidade && idBairro) {
        const retorno = await get(
          'api-enderecos',
          `/logradouros?id_cidade=${idCidade}&id_bairro=${idBairro}&nome=${logradouro}`
        );
        if (retorno.status === 200) {
          const opcoes = retorno.data.map((item: any) => ({
            idLogradouro: item.idlogradouro,
            logradouro: item.logradouro,
            faixa: item.faixa,
            cep: (item.cep !== null ? item.cep.replace('.', '') : item.cep)
          }));

          if (opcoes.length <= 5) {
            const opcao = opcoes.find((l: any) => logradouro.toLowerCase() === l.logradouro.trim().toLowerCase())
            if (opcao) setLogradouro({ nome: opcao.logradouro.trim(), id: opcao.idLogradouro });
          }

          return opcoes;
        }
      }

      return [];
    },
    [idCidade, idBairro, logradouro],
  );

  const refTimeouts = useRef({ cidades: -1, bairros: -1, logradouros: -1 });

  useEffect(() => {
    window.clearTimeout(refTimeouts.current.cidades);
    refTimeouts.current.cidades = window.setTimeout(() => listarCidades());
  }, [listarCidades]);

  useEffect(() => {
    window.clearTimeout(refTimeouts.current.bairros);
    refTimeouts.current.bairros = window.setTimeout(() => listarBairros());
  }, [listarBairros]);

  useEffect(() => {
    window.clearTimeout(refTimeouts.current.logradouros);
    refTimeouts.current.logradouros = window.setTimeout(() => listarLogradouros());
  }, [listarLogradouros]);

  useEffect(() => {
    const cepValido = cep !== '' ? regex.cep.test(cep) : null;
    const estadoValido = estado !== '' ? regex.estado.test(estado) : null;
    const cidadeValido = cidade !== '' ? regex.cidade.test(cidade) : null
    const idCidadeValido = !!idCidade && regex.idCidade.test(idCidade.toString());
    const bairroValido = bairro !== '' ? regex.bairro.test(bairro) : null;
    const logradouroValido = logradouro !== '' ? regex.logradouro.test(logradouro) : null;
    const numeroValido = numero !== '' ? regex.numero.test(numero) : null;

    const enderecoValido = {
      bairro: bairroValido,
      cep: cepValido,
      cidade: cidadeValido,
      estado: estadoValido,
      idCidade: idCidadeValido,
      logradouro: logradouroValido,
      numero: numeroValido,
    };

    setValido(enderecoValido);
    dispatch({
      type: 'VALIDA_ETAPA',
      etapaValida: Object.values(enderecoValido).every((v) => v),
    });
  }, [bairro, cep, cidade, dispatch, estado, idCidade, logradouro, numero]);

  const limparCampos = useCallback((...campos: string[]) => {
    dispatch({
      type: 'ALTERA_ENDERECO',
      ...campos.reduce((final, campo) => ({
        ...final,
        [campo]: null,
      }), {}),
    })
  }, [dispatch]);

  const valorCidade = (
    cidades.value?.find((c) => c.idCidade === idCidade)
    || cidade
  );

  const valorBairro = (
    bairros.value?.find((b) => b.idBairro === idBairro)
    || bairro
  );

  const valorLogradouro = (
    logradouros.value?.find((l) => l.idLogradouro === idLogradouro)
    || logradouro
  );

  return (
    <div className="etapa-formulario">
      <Subtitle gutterBottom>Informe seu endereço</Subtitle>

      <InputMask
        // autoFocus
        type='tel'
        mask='99999-999'
        maskChar=' '
        value={cep}
        // TODO: pensar se o pesquisaEnderecoPorCep deve estar aqui ou em useCalback ou useEffect.
        onChange={event => {
          setCEP(event.target.value);
          limparCampos(
            'estado',
            'cidade',
            'idCidade',
            'bairro',
            'idBairro',
            'logradouro',
            'idLogradouro',
            'numero',
          );
        }}>
          {
            (inputProps: any) =>
              <TextField
                {...inputProps}
                fullWidth
                label="CEP"
                autoComplete="postal-code"
                error={valido.cep !== null && !valido.cep}
                InputProps={{
                  endAdornment: localidade.loading && <CircularProgress size={20} />,
                }}
              />
          }
      </InputMask>

      <TextField
        fullWidth
        select
        label='Estado'
        error={valido.estado !== null && !valido.estado}
        value={estado}
        disabled={!valido.cep}
        onChange={event => {
          setEstado(event.target.value)
          limparCampos(
            'cidade',
            'idCidade',
            'bairro',
            'idBairro',
            'logradouro',
            'idLogradouro',
            'numero',
          );
        }}>
          {
            estadosOpcoes.map(opcao =>
              <MenuItem key={opcao.sigla} value={opcao.sigla}>
                {opcao.nome}
              </MenuItem>
            )
          }
      </TextField>

      <Autocomplete
        freeSolo
        clearOnEscape
        disableClearable
        getOptionLabel={option => typeof option === 'object' ? option.cidade : option}
        options={cidades.value ?? []}
        disabled={!valido.estado}
        value={valorCidade}
        onChange={(event: any, value: { cidade: string; idCidade: number } | string | null) => {
          setCidade(
            value && typeof value === 'object'
              ? { nome: value.cidade, id: value.idCidade }
              : { nome: value ?? '' },
          );
          limparCampos(
            'bairro',
            'idBairro',
            'logradouro',
            'idLogradouro',
            'numero',
          );
        }}
        onInputChange={(event: any, value) => setCidade({ nome: primeiraMaiusculaTodasPalavras(value) })}
        renderInput={(params) =>
          <TextField
            {...params}
            fullWidth
            label='Cidade'
            error={valido.cidade !== null && (!valido.cidade || !valido.idCidade)}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: cidades.loading ? <CircularProgress size={20} /> : params.InputProps.endAdornment,
            }}
          />
        }
      />

      <Autocomplete
        freeSolo
        clearOnEscape
        disableClearable
        getOptionLabel={option => typeof option === 'object' ? option.bairro : option}
        options={bairros.value ?? []}
        disabled={!valido.cidade}
        value={valorBairro}
        onChange={(event: any, value: { bairro: string; idBairro: number } | string | null) => {
          setBairro(
            value && typeof value === 'object'
              ? { nome: value.bairro, id: value.idBairro }
              : { nome: value ?? '' },
          );
          limparCampos(
            'logradouro',
            'idLogradouro',
            'numero',
          );
        }}
        onInputChange={(event: any, value) => {
          const tempValuePrefixo = value.substring(0, value.indexOf(' ')).toLowerCase();
          const tempValueSemPrefixo = value.substring(value.indexOf(' '));

          // Corrige prefixo
          const regrasCorrecaoPrefixo = [
            { regex: /^vl|^vl./i, replace: 'Vila' },
            { regex: /^jd|^jd./i, replace: 'Jardim' },
            { regex: /^pq|^pq./i, replace: 'Parque' },
            { regex: /^lot|^lot./i, replace: 'Loteamento' },
            { regex: /^conj|^conj./i, replace: 'Conjunto' },
          ];

          const valor = regrasCorrecaoPrefixo.reduce((final, regra) => {
            if (regra.regex.test(tempValuePrefixo))
              return regra.replace + tempValueSemPrefixo;

            return final;
          }, value.valueOf());

          setBairro({ nome: primeiraMaiusculaTodasPalavras(valor) });
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            fullWidth
            label='Bairro'
            error={valido.bairro !== null && !valido.bairro}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: bairros.loading ? <CircularProgress size={20} /> : params.InputProps.endAdornment,
            }}
          />
        }
      />

      <Autocomplete
        freeSolo
        clearOnEscape
        disableClearable
        getOptionLabel={option => {
          if (typeof option === 'object') {
            let logradouro = option.logradouro;
            logradouro += (option.faixa !== null ? ` ${option.faixa}` : '');
            return logradouro;
          }

          return option;
        }}
        options={logradouros.value ?? []}
        value={valorLogradouro}
        disabled={!valido.bairro}
        onChange={(event: any, value: { logradouro: string; idLogradouro: number, faixa: string; cep: string | null; } | string | null) => {
          setLogradouro(
            value && typeof value === 'object'
              ? { nome: value.logradouro, id: value.idLogradouro }
              : { nome: value ?? '' },
          );
          limparCampos('numero');
        }}
        onInputChange={(event: any, value) => {
          const tempValuePrefixo = value.substring(0, value.indexOf(' ')).toLowerCase();
          const tempValueSemPrefixo = value.substring(value.indexOf(' '));

          // Corrige prefixo
          const regrasCorrecaoPrefixo = [
            { regex: /^r|^r./i, replace: 'Rua' },
            { regex: /^al|^al./i, replace: 'Alameda' },
            { regex: /^av|^av./i, replace: 'Avenida' },
          ];
          regrasCorrecaoPrefixo.forEach(regra => {
            if (regra.regex.test(tempValuePrefixo))
              value = regra.replace + tempValueSemPrefixo;
          });

          const valor = regrasCorrecaoPrefixo.reduce((final, regra) => {
            if (regra.regex.test(tempValuePrefixo))
              return regra.replace + tempValueSemPrefixo;

            return final;
          }, value.valueOf());

          setLogradouro({ nome: primeiraMaiusculaTodasPalavras(valor) });
        }}
        renderInput={(params) =>
          <TextField
            {...params}
            fullWidth
            label='Logradouro'
            placeholder='Rua, Avenida, Alameda...'
            error={valido.logradouro !== null && !valido.logradouro}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: logradouros.loading ? <CircularProgress size={20} /> : params.InputProps.endAdornment,
            }}
          />
        }
      />

      <TextField
        fullWidth
        label='Número'
        disabled={!valido.logradouro}
        error={valido.numero !== null && !valido.numero}
        value={numero}
        onChange={event => setNumero(event.target.value)}
        autoComplete="off"
      />

      <TextField
        fullWidth
        label='Complemento'
        value={complemento}
        onChange={event => setComplemento(primeiraMaiusculaTodasPalavras(event.target.value))}
        InputProps={{
          endAdornment: <InputSuffix position='end'>(opcional)</InputSuffix>
        }}
        autoComplete="off"
      />
    </div>
  );
}
