import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router';
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';

import { post } from '../../_axios/requisicao';
import { _404 } from '../../paginas';
import { Inicial, Final, Etapas } from './paginas';
import QueroSerVoluntario from './paginas/Final/Vereador/QueroSerVoluntario';
import Confirmacao from './paginas/Final/Confirmacao';
import { etapasDeputado, etapasPadroes } from './paginas/Etapas/config';
import { Cpf } from '../../paginas/Formulario/Dados';
import { Endereco } from '../../paginas/Formulario/Endereco';
import { Celular } from '../../paginas/Formulario/Contatos';
import EstruturaEtapaFormulario from './componentes/EstruturaEtapaFormulario';

import { getSchema, getReferenciaPeloDominio } from '../../_recursos/js/util';

import ArcoIcon from '../../_recursos/imagens/arco-icon.svg';
import LibcomIcon from '../../_recursos/imagens/libcom-icon.svg';
import { useQuery } from '../../_hooks';
import { IStoreRedux } from 'Types/Reducers';


interface IRouterParams {
  referencia: string,
  // idUsuario: string,
  // assinaturaUrl: string,
}

export default function Cadastro() {
  const dispatch = useDispatch();
  const { deputado, etapaValida, hash, carregando } = useSelector((state: IStoreRedux) => state.app);
  const cpf = useSelector((state: IStoreRedux) => state.dados.cpf);
  const contatos = useSelector((state: IStoreRedux) => state.contatos);
  const endereco = useSelector((state: IStoreRedux) => state.endereco);
  const termos = useSelector((state: IStoreRedux) => state.termos);

  let {
    referencia = getReferenciaPeloDominio(),
    // Rota antiga
    // idUsuario,
    // assinaturaUrl
  } = useParams<IRouterParams>();

  const match = useRouteMatch();
  const historicoNavegacao = useHistory();

  const [schema, setSchema] = useState<string>('');
  const [termo, setTermo] = useState<IState | null>(null);
  const [icone, setIcone] = useState<string | null>(null);
  const [codigoReferencia, setCodigoReferencia] = useState<string>('');
  const [etapaCpf] = useState<any>({
    path: `/cadastro/formulario/dados/cpf`,
    component: <Cpf />
  });
  const [etapaEndereco] = useState<any>({
    path: '/cadastro/formulario/endereco',
    component: <Endereco />,
  })
  const [etapaCelular] = useState<any>({
    path: '/cadastro/formulario/contatos/celular',
    component: <Celular />,
  })

  const query = useQuery();

  const resetApp = useCallback(() => {
    dispatch({ type: 'ALTERA_HASH', hash: null });
    dispatch({ type: 'RESET_CONTATOS' });
    dispatch({ type: 'RESET_VEREADOR' });
    dispatch({ type: 'RESET_DADOS' });
    dispatch({ type: 'RESET_ENDERECO' });
    dispatch({ type: 'RESET_EVENTO' });
    dispatch({ type: 'ALTERA_INSCRITO', inscrito: false});
    dispatch({ type: 'ALTERA_INSCRITO_VOLUNTARIADO', inscritoVoluntariado: false});
    // dispatch({ type: 'ALTERA_IDUSUARIO', idUsuario });
    dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });
  }, [dispatch]);

  const capturaUri = useMemo(() => historicoNavegacao.location.pathname, [historicoNavegacao.location.pathname])

  const etapasAtuais = useMemo(() => deputado ? etapasDeputado : etapasPadroes, [deputado]);

  const capturaEtapaAtual = useCallback((): IState | any => {
    if (etapasAtuais.length) {
      let etapaSelecionada = etapasAtuais.filter((etapa: any, index: number) => {
        if (etapa.path === capturaUri) {
          etapa.posicao = index;
          return etapa;
        }
      })[0];

      if (etapaSelecionada && Object.keys(etapaSelecionada).length)
        return etapaSelecionada;
      else
        return { posicao: 0, componente: <Redirect to={etapasAtuais[0].path} push/> }
    }

    return;
  }, [capturaUri, etapasAtuais]);

  const init = useCallback(() => {
    resetApp();

    if (referencia.match(/^(libcom|arco)$/)) {
      dispatch({ type: 'ALTERA_REFERENCIA', referencia });
      setCodigoReferencia(referencia);
      setIcone(referencia === 'libcom' ? LibcomIcon : ArcoIcon);
    } else if (referencia.length < 10) {
      dispatch({ type: 'ALTERA_REFERENCIA', referencia });
      setCodigoReferencia(referencia);
    } else {
      dispatch({ type: 'ALTERA_HASH', hash: referencia });
      setCodigoReferencia(referencia.substring(0,6));
    }

    dispatch({ type: 'ALTERA_CARREGANDO', carregando: false });
  }, [dispatch, referencia, resetApp]);

  useEffect(() => {
    if (codigoReferencia) {
      setSchema(getSchema(codigoReferencia?.substr(0, 6)));
    }
  }, [codigoReferencia]);

  const inscricaoComCPF = useCallback(async () => {
    dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });

    const inscricao = { cpf, contatos, endereco, termos };

    // Remove contato quando nao informado
    if (!contatos.celular || contatos.celular.numero === null)
      delete inscricao.contatos;

    // Remove endereco quando nao informado
    if (!endereco || endereco.logradouro === null)
      delete inscricao.endereco;

    try {
      const retorno = await post('api-pessoas', '/inscricoes-com-cpf', inscricao,
        hash ? { hash } : { referencia /*, usuario: idUsuario */ }
      );

      // Quando inscricao realizada
      if (retorno && retorno.status === 200) {
        if (retorno.data.dados) {
          dispatch({ type: 'ALTERA_CARREGANDO', carregando: false });
          dispatch({ type: 'RESET_CONTATOS' });
          dispatch({ type: 'RESET_DADOS' });
          dispatch({ type: 'RESET_ENDERECO' });
          dispatch({ type: 'RESET_TERMOS'})
          dispatch({ type: 'ALTERA_EVENTO', ...retorno.data.evento });
          dispatch({ type: 'ALTERA_INSCRITO', inscrito: true });
          historicoNavegacao.push('/cadastro/formulario/confirmacao');
          return;
        }
      }
    } catch (data) {
      dispatch({ type: 'ALTERA_CARREGANDO', carregando: false });

      // Quando inscricao nao realizada
      if (data.status === 400) {

        // Cadastro nao existe
        if (data.error.cadastro_existe === false) {
          return {
            cadastro_existe: false
          }
        }

        // Cadastro existe com dados pendentes
        if (data.error.solicitar_celular || data.error.solicitar_endereco) {
          return {
            cadastro_existe: true,
            solicitar_celular: data.error.solicitar_celular,
            solicitar_endereco: data.error.solicitar_endereco,
          };
        }
      }
    }
  }, [contatos, cpf, dispatch, endereco, hash, historicoNavegacao, referencia, termos]);

  const validaCadastro = useCallback(async () => {
    const valida = await inscricaoComCPF();

    if (valida) {
      if (valida.cadastro_existe) {
        etapasAtuais.splice(0, etapasAtuais.length);
        if (valida.solicitar_celular && !valida.solicitar_endereco) {
          etapasAtuais.splice(0, 0, etapaCelular);
          etapasAtuais[0].enviaInscricao = true;
          historicoNavegacao.push(`${etapasAtuais[0].path}`);
          return;
        } else if (valida.solicitar_endereco && !valida.solicitar_celular) {
          etapasAtuais.splice(0, 0, etapaEndereco);
          etapasAtuais[0].enviaInscricao = true;
          historicoNavegacao.push(`${etapasAtuais[0].path}`);
          return;
        } else if (valida.solicitar_celular && valida.solicitar_endereco) {
          etapasAtuais.splice(0, 0, etapaCelular);
          etapasAtuais.splice(1, 0, etapaEndereco);
          etapasAtuais[1].enviaInscricao = true;
          historicoNavegacao.push(`${etapasAtuais[0].path}`);
          return;
        }
      } else {
        historicoNavegacao.push(`${etapasAtuais[0].path}`);
      }
    }

  }, [etapaCelular, etapaEndereco, etapasAtuais, historicoNavegacao, inscricaoComCPF]);

  const forcarEtapaCPF = useMemo(() => (deputado || hash?.startsWith('co')), [deputado, hash]);

  const appConfig = {
    schema,
    referencia,
    // idUsuario,
    // assinaturaUrl,
    irParaEvento: query.get('irparaevento') === 'true',
    icone,
    termo,
    etapaPath: forcarEtapaCPF ? etapaCpf.path : etapasAtuais[0].path,
    setTermo,
    init,
  }

  return (
    <>
      <Switch>
        {/* Página Inicial */}
        <>
          <Route
            path={`${match.path}/`}
            render={() => <Inicial config={appConfig} />}
            exact
          />

          {/* Página de Confirmação */}
          <Route
            path="/cadastro/formulario/confirmacao"
            render={() => <Final config={appConfig} />}
          />

          {/* Página de Voluntário para vereadores */}
          <Route path="/cadastro/quero-ser-voluntario" component={QueroSerVoluntario} />

          {/* Página de Voluntário para vereadores */}
          <Route path="/cadastro/voluntario" component={Confirmacao} />

          {forcarEtapaCPF && (
            <Route path={etapaCpf.path}>
              <EstruturaEtapaFormulario
                etapaAtual={etapaCpf}
                callbackAvancar={validaCadastro}
                desabilitaBotao={etapaValida !== true}
                spinner={carregando} />
            </Route>
          )}

          {/* Etapas */}
          {capturaEtapaAtual().path
            && !capturaEtapaAtual().path.includes('confirmacao' || 'voluntario') && (
            <Route path={capturaEtapaAtual().path} exact>
              <Etapas
                etapas={etapasAtuais}
                etapaAtual={capturaEtapaAtual()}
                forcarEtapaCPF={forcarEtapaCPF}
                inscricaoComCPF={inscricaoComCPF}
              />
            </Route>
          )}
        </>

        {referencia === 'default' && <Route component={_404} />}
      </Switch>
    </>
  )
}