import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useSelector, useDispatch } from 'react-redux';
import { TextField, FormControlLabel, Checkbox } from '@material-ui/core';

import { TextSmall } from "../../../componentes/Interface";

export default function Celular() {
  const celular = useSelector(state => state.contatos.celular);
  const dispatch = useDispatch();
  const [valido, setValido] = useState(null);
  let celularNumeroAlterado = null;

  useEffect(() => valida(celular ? celular.numero : ''), []);
  useEffect(() => void dispatch({ type: 'ALTERA_ETAPA_ATUAL', etapaAtual: 'celular' }), [dispatch]);

  function altera(event) {
    celularNumeroAlterado = event.target.value;
    dispatch({ type: `ALTERA_CELULAR`, numero: celularNumeroAlterado });
    valida(celularNumeroAlterado);
  }

  function valida(celularNumero) {
    const regex = /\([1-9][0-9]\)\s?[9]?\s?\d{4}([\s-])?\d{4}/;
    const resultado = regex.test(celularNumero);
    (celularNumero !== null && setValido(resultado));
    dispatch({ type: `VALIDA_ETAPA`, etapaValida: resultado });
  }

  function alteraWhatsApp(event) {
    dispatch({ type: `ALTERA_CELULAR`, whatsapp: event.target.checked });
  }

  return (
    <div className="etapa-formulario">
      <InputMask
        fullWidth
        label='Celular'
        type='tel'
        mask='(99) 99999-9999'
        maskChar=' '
        error={valido !== null && !valido}
        value={celular && celular.numero ? celular.numero : ''}
        onChange={altera}>
        {(inputProps) => <TextField {...inputProps} autoComplete="tel" />}
      </InputMask>

      <FormControlLabel
        label={
          <TextSmall>Este número tem WhatsApp</TextSmall>
        }
        control={
          <Checkbox
            value={celular ? celular.whatsapp : false}
            color='primary'
            checked={celular ? celular.whatsapp : false}
            onChange={alteraWhatsApp}
          />
        }
      />
    </div>
  );
}
