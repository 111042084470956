import React, { useEffect, useState, useCallback, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { post, get } from '../../../../_axios/requisicao';

import { Button, CircularProgress, IconButton } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';

import { Container, Content, Toolbar } from '../../../../componentes/Interface';
import Spinner from '../../../../componentes/Spinner';

import Toast from '../../../../componentes/Toast';
import initWebcheckout, { configCheckoutPagarMe } from '../../../../_recursos/js/configCheckoutPagarMe';
import {
  TEXTO_SPINNER_CPF,
  TEXTO_SPINNER_INSCRICAO,
  TEXTO_CPF_INVALIDO,
  TEXTO_SERVER_ERROR,
  TEXTO_SPINNER_PAGAMENTO_PENDENTE,
  TEXTO_NAO_PERMITE_INSCRICAO
} from './textos';
import { IStoreRedux } from 'Types/Reducers';

let checkoutPagarme: any;

export default function Etapas({ etapas, etapaAtual, config }: any) {
  const dispatch = useDispatch();
  const stateApp = useSelector((state: IStoreRedux) => state.app);
  const dados = useSelector((state: IStoreRedux) => state.dados);
  const contatos = useSelector((state: IStoreRedux) => state.contatos);
  const endereco = useSelector((state: IStoreRedux) => state.endereco);
  const termos = useSelector((state: IStoreRedux) => state.termos);
  const eventoEscola = useSelector((state: IStoreRedux) => state.eventoEscola);

  const { cpf, isento } = dados;

  const historicoNavegacao = useHistory();

  const [pedidoOdoo, setPedidoOdoo] = useState<IPedidoOdoo>();
  const [abreCheckout, setAbreCheckout] = useState<boolean>(false);

  const [posicaoEtapaAtual, setPosicaoEtapaAtual] = useState<number>(0);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [mesangemSpinner, setMensagemSpinner] = useState<JSX.Element>(<></>);
  const [exibeMensagemErro, setExibeMensagemErro] = useState<boolean>(false);
  const [mensagemErro, setMensagemErro] = useState<string>('');

  const exibeMetodoPagamento = useCallback((pedidoOdoo: any) => {
    const values = { dados, contatos, endereco, eventoEscola }
    const config = configCheckoutPagarMe(values as any, stateApp.idPessoa as string, stateApp.corPrimaria as string, pedidoOdoo);

    const errorCallback = (error: any) => {
      setMensagemErro(TEXTO_SERVER_ERROR);
      setExibeMensagemErro(true);
      console.error(error);
    };

    const checkout = new checkoutPagarme.Checkout({
      encryption_key: process.env.REACT_APP_PAGARME_ENCRYPTION_KEY,
      success: async (success: any) => {
        setAbreCheckout(false);
        setExibeMensagemErro(false);
        try {
          const response: any = await get('api-pessoas', `/captura-transacao-pagarme/${success.token}?valor=${config.amount}`, {});
          const { data, status } = response;

          if (status === 200) {
            const boletoProps = {
              boletoURL: data?.boletoURL,
              boletoBarcode: data?.boletoBarcode,
              boletoExpirationDate: data?.boletoExpirationDate,
            };

            dispatch({ type: 'ALTERA_BOLETO', boleto: boletoProps });
            historicoNavegacao.push('/inscricao/confirmacao');
          }
        } catch (error) {
          errorCallback(error);
        }
      },
      error: errorCallback,
      close: () => setTimeout(() => checkout.open(config), 700),
    });

    checkout.open(config);
  }, [contatos, dados, dispatch, endereco, eventoEscola, historicoNavegacao, stateApp.corPrimaria, stateApp.idPessoa]);

  // exibe o metodo de pagamento quando houver pedido da odoo
  useEffect(() => {
    if (pedidoOdoo && abreCheckout) {
      exibeMetodoPagamento(pedidoOdoo);
    }
  }, [pedidoOdoo, abreCheckout, exibeMetodoPagamento]);

  const validaHashCpf = useCallback(async () => {
    const uriInscricaoEvento = `/valida-hash-inscricao?cpf=${cpf}&hash_inscricao=${stateApp.hash}`;
    setMensagemSpinner(TEXTO_SPINNER_CPF);
    setSpinner(true);

    try {
      const retorno = await get('api-pessoas', uriInscricaoEvento, { schema: 'libcom' });

      if (retorno) {
        setSpinner(false);
        setExibeMensagemErro(false);

        const {
          nome,
          email,
          celular,
          dtNascimento,
          isento,
          idconvidado,
          endereco_cep,
          endereco_estado,
          endereco_cidade,
          endereco_logradouro,
          endereco_numero,
          visitadoporid
        } = retorno.data.convidado;

        const {
          status,
          cidade,
          data,
          idevento,
          data_inscricao,
          socio,
          termo_inscricao,
          titulo,
          valor_evento,
          valor_inscricao,
          qtd_parcelas,
          permite_inscricao
        } = retorno.data.evento;

        const { pedidoOdoo } = retorno.data;

        const celularFormatado = celular ? celular.replace(/\D/g, '').replace(/^(\d\d)(\d)/, '($1) $2').replace(/(\d{5})(\d)/, '$1-$2').replace(/(\s\d{4})\d+?$/, '$1') : celular;
        const statusFormatado = status ? status.toLowerCase().replace(/\s/g, '-') : status;

        if (!permite_inscricao && (statusFormatado !== 'pagamento-andamento' || statusFormatado !== 'pagamento-pendente')) {
          setMensagemErro(TEXTO_NAO_PERMITE_INSCRICAO);
          setExibeMensagemErro(true);
          return false;
        }


        dispatch({ type: 'ALTERA_IDPESSOA', idPessoa: idconvidado });

        dispatch({ type: 'ALTERA_NOME', nome: nome.trim() });
        dispatch({ type: 'ALTERA_CELULAR', numero: celularFormatado, tipo: 4, whatsapp: true });
        dispatch({ type: 'ALTERA_EMAIL', tipo: 1, endereco: email });
        if (dtNascimento && dtNascimento.length === 9) {
          const dataFormatada = dtNascimento.split('-');
          dispatch({ type: 'ALTERA_DATA_NASCIMENTO', dataNascimento: `${dataFormatada[0]}-${dataFormatada[1]}-0${dataFormatada[2]}` });
        } else {
          dispatch({ type: 'ALTERA_DATA_NASCIMENTO', dataNascimento: dtNascimento });
        }
        dispatch({ type: 'ALTERA_ISENTO', isento });
        dispatch({ type: 'ALTERA_VISITADOPORID', visitadoporid });

        const endereco = {
          cep: endereco_cep,
          estado: endereco_estado,
          cidade: endereco_cidade,
          // bairro: action.bairro,
          logradouro: endereco_logradouro,
          numero: endereco_numero,
        }
        dispatch({ type: 'ALTERA_ENDERECO', ...endereco });

        dispatch({ type: 'ALTERA_CIDADE', cidade });
        dispatch({ type: 'ALTERA_DATA', data });
        dispatch({ type: 'ALTERA_IDEVENTO', idevento });
        dispatch({ type: 'ALTERA_DATA_INSCRICAO', data_inscricao });
        dispatch({ type: 'ALTERA_SOCIO', socio });
        dispatch({ type: 'ALTERA_STATUS', status: statusFormatado | isento && 'isento' });
        dispatch({ type: 'ALTERA_TERMO_INSCRICAO', termo_inscricao });
        dispatch({ type: 'ALTERA_TITULO', titulo });
        dispatch({ type: 'ALTERA_VALOR_EVENTO', valor_evento });
        dispatch({ type: 'ALTERA_VALOR_INSCRICAO', valor_inscricao });
        dispatch({ type: 'ALTERA_PARCELAS', qtd_parcelas });

        const statusPagamento = (
          statusFormatado === 'pagamento-andamento'
          || statusFormatado === 'pagamento-pendente'
          || statusFormatado === 'pagamento-cancelado'
          || statusFormatado === 'pagamento-devolvido'
        );

        if (pedidoOdoo.id && statusPagamento) {
          setPedidoOdoo(pedidoOdoo);
          setAbreCheckout(true);
          setMensagemSpinner(TEXTO_SPINNER_PAGAMENTO_PENDENTE);
          setSpinner(true);
          return;
        }

        if (statusFormatado === 'isento' || statusFormatado === 'inscrito') {
          historicoNavegacao.push('/inscricao/confirmacao');
          return;
        }

        historicoNavegacao.push(etapas[posicaoEtapaAtual + 1].path);
        return true;
      } else {
        setMensagemErro(TEXTO_CPF_INVALIDO);
        setExibeMensagemErro(true);
        return false;
      }

    } catch (error) {
      console.error(error)
      setMensagemErro(TEXTO_CPF_INVALIDO);
      setPedidoOdoo(undefined);
      setAbreCheckout(false);
      setExibeMensagemErro(true);
      setSpinner(false);
      return false;
    }
  }, [cpf, dispatch, etapas, historicoNavegacao, posicaoEtapaAtual, stateApp.hash]);

  const inscreveAluno = useCallback(async () => {
    const uriInscricaoAluno = `/inscricoes/${stateApp.hash}`;
    const inscricao = { dados, contatos, endereco, termos };

    const header = {
      /* hash: stateApp.hash, */ // cabecalho apaenas para hashlink
      referencia: stateApp.referencia,
      usuario: stateApp.idUsuario || '',
      responsavel: dados.visitadoporid || '',
      schema: config.schema,
    }
    setMensagemSpinner(TEXTO_SPINNER_INSCRICAO);
    setSpinner(true);

    try {
      const retorno = await post('api-pessoas', uriInscricaoAluno, inscricao, header);
      if (retorno) {
        setSpinner(false);
        setExibeMensagemErro(false);
        return retorno.data.pedidoOdoo;
      } else {
        setMensagemErro(TEXTO_SERVER_ERROR);
        setExibeMensagemErro(true);
        return false;
      }

    } catch (error) {
      console.error(error);
      setMensagemErro(TEXTO_SERVER_ERROR);
      setExibeMensagemErro(true);
      setSpinner(false);
      return false;
    }
  }, [config.schema, contatos, dados, endereco, stateApp.hash, stateApp.idUsuario, stateApp.referencia, termos]);

  const navegaParaEtapaSeguinte = useCallback(async () => {
    const uriCPF = '/inscricao/formulario/dados/cpf';
    const uriTermo = '/inscricao/formulario/termo-adesao';
    let resultadoValidaCPF: boolean | undefined = false;


    if (posicaoEtapaAtual === etapas.length - 1) return;

    const posicaoEtapa = (posicaoEtapaAtual + 1);
    const urlDestino = etapas[posicaoEtapa].path;

    const etapaAtualPath = etapas[posicaoEtapaAtual].path;

    if (etapaAtualPath === uriCPF) {
      resultadoValidaCPF = await validaHashCpf();
      if(!resultadoValidaCPF)
        return;
    } else if (etapaAtualPath === uriTermo) {
      const pedidoOdoo = await inscreveAluno();

      if (!isento && pedidoOdoo) {
        setSpinner(true);
        setAbreCheckout(true);
        setPedidoOdoo(pedidoOdoo);
        return;
      } else if (!pedidoOdoo) {
        setMensagemErro(TEXTO_SERVER_ERROR);
        setExibeMensagemErro(true);
        return;
      }
    }

    historicoNavegacao.push(urlDestino);
    setPosicaoEtapaAtual(posicaoEtapa);
    setExibeMensagemErro(false);
  }, [etapas, historicoNavegacao, inscreveAluno, isento, posicaoEtapaAtual, validaHashCpf]);

  const navegaParaEtapaAnterior = useCallback(() => {
    if (posicaoEtapaAtual <= 0) return;
    const posicaoEtapa = (posicaoEtapaAtual - 1);
    setPosicaoEtapaAtual(posicaoEtapa);
    const etapaAnterior = etapas.filter((etapa: any, index: any) => index === posicaoEtapa);

    historicoNavegacao.push(etapaAnterior[0].path);
  }, [etapas, historicoNavegacao, posicaoEtapaAtual]);

  useEffect(() => {
    if(stateApp.inscrito) {
      historicoNavegacao.push('/inscricao/confirmacao');
    }

    setPosicaoEtapaAtual(etapaAtual.posicao);
    dispatch({ type: `ALTERA_CARREGANDO`, carregando: false });
  }, [dispatch, etapaAtual.posicao, historicoNavegacao, stateApp.inscrito]);

  useEffect(() => {
    if (etapaAtual.path.includes('termo-adesao') || etapaAtual.path.includes('cpf')) {
      initWebcheckout(() => {
        checkoutPagarme = window.PagarMeCheckout;
      });
    }
  }, [etapaAtual]);

  return (
    <Container>
      {spinner && <Spinner texto={mesangemSpinner}/>}

      {/* Toolbar */}
      <Content>
        <Toolbar>
          {posicaoEtapaAtual > 0 &&
            <IconButton onClick={navegaParaEtapaAnterior}>
              <ArrowBack />
            </IconButton>
          }
          {stateApp.carregando && <CircularProgress color='primary' size={24}/>}
        </Toolbar>
      </Content>
      <Content full padding pb="3rem">
        {/* Campo do Input */}
        {etapaAtual.component}
        <br/><br/>

        <Button
          disabled={stateApp.etapaValida !== true}
          size='large'
          variant='contained'
          color='primary'
          onClick={() => navegaParaEtapaSeguinte()}
        >
          {etapaAtual.path.includes('termo-adesao') ? 'Aceitar e Continuar' : 'Continuar'}
        </Button>
        <Toast
          exibe={exibeMensagemErro}
          setExibe={setExibeMensagemErro}
          mensagem={mensagemErro}
          duration={10000}
          botao
        />
      </Content>
    </Container>
  )
}