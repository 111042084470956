const INITIAL_STATE = {
  // Celular Principal
  celular: null,
  // Email Pessoal
  email: null
};

const INITIAL_CELULAR = {
  tipo: 4,
  numero: null,
  whatsapp: true
}

function contatos(state = INITIAL_STATE, action) {
  const trueAction = { ...action };
  delete trueAction.type;
  switch (action.type) {
    case 'ALTERA_CELULAR':
      return {
        ...state,
        celular: {
          ...(state.celular || INITIAL_CELULAR),
          ...trueAction
        }
      };

    case 'ALTERA_EMAIL':
      return {
        ...state,
        email: {
          ...state.email,
          ...trueAction
        }
      };

    case 'REMOVE_EMAIL':
      return {
        ...state,
        email: null
      };

    case 'RESET_CONTATOS':
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default contatos;