import React, { useEffect, useReducer, useRef } from 'react';
import { useDispatch } from 'react-redux';

import InscricaoContext from '.';
import { get } from '../../../_axios/requisicao';
import { detectaDispositivo, getReferenciaPeloDominio, verificaPadraoHashLink } from '../../../_recursos/js/util';
import { useParams } from 'react-router-dom';
import { reducerConfigInscricao } from './reducer';

interface IRouterParams {
  hash?: string;
}

function InscricaoProvider({ children }: React.PropsWithChildren<{}>) {
  const { hash } = useParams<IRouterParams>()

  const dispatch = useDispatch()

  const refConfigCarregado = useRef(false);
  const [config, dispatchConfig] = useReducer(reducerConfigInscricao, {})

  useEffect(() => {
    if (hash?.startsWith('formulario') && !refConfigCarregado.current)
      return void dispatchConfig({ type: 'SET', payload: null });

    if (refConfigCarregado.current) return;

    dispatch({ type: 'RESET_APP' });
    dispatch({ type: 'RESET_DADOS' });
    dispatch({ type: 'RESET_CONTATOS' });
    dispatch({ type: 'RESET_ENDERECO' });
    dispatch({ type: 'RESET_TERMOS' });
    dispatch({ type: 'RESET_VEREADOR' });
    dispatch({ type: 'ALTERA_CARREGANDO', carregando: true });
    const hashOuVazio = hash ?? '';
    const hashValido = verificaPadraoHashLink(hashOuVazio);
    const referenciaValida = /^\d{4}[a-z]{2}$/.test(hashOuVazio)
    const referencia = (
      (hashValido && /^\d{4}[a-z]{2}/.test(hashOuVazio) && hash?.substring(0, 6))
      || (hashValido && ( /^bcom/.test(hashOuVazio) ? 'libcom' : 'arco' ))
      || (referenciaValida && hash)
      || getReferenciaPeloDominio()
    );

    get('api-pessoas', `/inscricoes/${hashValido ? hash : ''}`, { referencia })
      .then((res) => {
        refConfigCarregado.current = true;
        dispatchConfig({ type: 'SET', payload: res.data })
        dispatch({ type: 'ALTERA_HASH', hash: hash ?? null });
        dispatch({ type: 'ALTERA_REFERENCIA', referencia })
        dispatch({
          type: 'ALTERA_TERMOS',
          termos: res.data.etapas.termos.map((t: any) => ({
            idTermo: t.idTermo,
            idTermoTipo: t.idTermoTipo,
            dispositivo: detectaDispositivo(),
            aceito: false,
          }))
        });
      })
      .catch((e) => {
        console.error(e)
        dispatchConfig({ type: 'SET', payload: null }); // redirecionar pagina 404
      })
      .finally(() => dispatch({ type: 'ALTERA_CARREGANDO', carregando: false }))
  }, [config, dispatch, hash])

  return (
    <InscricaoContext.Provider value={config}>
      {children}
    </InscricaoContext.Provider>
  )
}

export default InscricaoProvider;
